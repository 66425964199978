.messagealert{
  background-color: #333336e3; 
  padding: 20px;
  color: #FFF;
  margin: 20px;
  position: fixed;
  z-index: 9999;
  width: 450px; /* Need a specific value to work */
  box-shadow: var(--shadow-gen);
  display: flex;
  gap: 5px;
  border-left: 5px solid var(--colour-first);
  bottom: 15px;
  right: 15px;
  transition: all  .25s ease;
}

.slideinimage {
  animation: slidein .25s;
  -webkit-animation: slidein .25s;
  -moz-animation: slidein .25s;
  -o-animation: slidein .25s;
  -ms-animation: slidein .25s;
}
@keyframes slidein {
  0% {right:-600px;opacity:0;}
  100% {right:15px;opacity:1;}
}

@-moz-keyframes slidein {
  0% {right:-600px;opacity:0;}
  100% {right:15px;opacity:1;}
}

@-webkit-keyframes slidein {
  0% {right:-600px;opacity:0;}
  100% {right:15px;opacity:1;}
}

@-o-keyframes slidein {
  0% {right:-600px;opacity:0;}
  100% {right:15px;opacity:1;}
}

@-ms-keyframes slidein {
  0% {right:-600px;opacity:0;}
  100% {right:15px;opacity:1;}
}

.messagealert2{
  border-left: 5px solid var(--status-color-two);
}

.messagealert3{
  border-left: 5px solid var(--status-color-three);
}

.messagealert.alert_1{
  border-left: 5px solid var(--status-color-one);
}

.messagealert.alert_2{
  border-left: 5px solid var(--status-color-two);
}

.messagealert.alert_3{
  border-left: 5px solid var(--status-color-two);
}

.messagealert  .message_icon{
  width: 60px;
}
.messagealert .message_con{
  width: 100%;
  color: #FFF;
  font-size: 16px;
}

.messagealert .close_message{
  width: 30px;
}

.messagealert .close_message:hover{
  opacity: .5;
  cursor: pointer;
}