:root{
  --portal-font-fam:'Lato', sans-serif;
  --portal-sidebar-bg:rgba(255,255,255,.8);
  --portal-single-prop-bg:rgba(255,255,255,.9);
  --portal-colour-first:#2BC687;
  --portal-colour-second:#333336;
  --portal-font-size-xxxlarge:45px;
  --portal-font-size-xxlarge:35px;
  --portal-font-size-xlarge:25px;
  --portal-font-size-large:20px;
  --portal-font-size-med:15px;
  --portal-font-size-gen:13px;
  --portal-font-size-small:10px;
  --portal-font-size-xsmall:8px;
  --portal-round-corners:5px;
  --portal-shadow-gen:0px 0px 15px -4px rgba(0,0,0,0.5);
  --portal-status-color-one:#2BC687;
  --portal-status-color-two:#FBC130;
  --portal-status-color-three:#EC0000;
  --portal-colour-gen-light:#EBEBEB;
}

body{
  background-color: #EBEBEB;
}


.portal_notes_alert_box{
  padding: 20px;
  background: var(--portal-status-color-three);
  color: #FFF;
  margin-top: 20px;
  box-sizing: border-box;
}

.download_link{
  color: var(--portal-colour-first);
  text-decoration: none;
}

.little_note{
  background-color: var(--portal-colour-second);
  color: #FFF;
  font-size: var(--portal-font-size-gen);
  padding: 20px;
  margin-bottom: 30px;
}

.little_note_error{
  background-color: var(--portal-status-color-three);
  color: #FFF;
  font-size: var(--portal-font-size-gen);
  padding: 15px;
  margin-bottom: 30px;
}

.upload_con .little_note_error{
  box-sizing: border-box;
}

.upload_con .little_note_error .row{
  margin: 3px;
}

.upload_con  .gen_copy{
  font-size: 14px;
  color: #FFF;
  margin-bottom: 20px;

}


.login_con{
  background-color: var(--portal-colour-first);
  width: 100vw;
  height: 100vh;
  /* background-image: url("./portal_bg.jpg"); */
}

.login_con .sidebar{
  background-color: #FFF;
  width: 100%;
  max-width: 400px;
  padding: 30px;
  position: absolute;
  left: 0px;
  height: 100vh;
}

.login_con .sidebar .loadingicon{
  margin: 0 auto;
  margin-bottom: 30px;

}

.uploaded_con{
  height: 550px;
  overflow: scroll;
}

.upload_message_1{
  background-color: #166B48;
  padding: 10px;
  color: #FFF;
  box-sizing: border-box;

}

.login_con form{
  width: 100%;
  margin-top: 20px;
}

.login_con form label{
  color: var(--portal-colour-second);
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: bold;
  display: table;
}

.login_con .login_icon{
  margin: 0 auto;
  display: table;
  margin-bottom: 30px;

}

.login_con .login_heading{
  font-size: var(--portal-font-size-med);
  color: var(--portal-colour-second);
  font-weight: bold;
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.login_con form div{
  position: relative;
}
.login_con form input{
  width: 100%;
  background-color: #D8D8D8;
  border: 0px;
  box-sizing: border-box;
  border-radius: var(--portal-round-corners);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  margin-bottom: 5px;
}


.btn_base{
  background-color: var(--portal-colour-second);
  color: #FFF;
  border-radius: var(--portal-round-corners);
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  box-sizing: border-box;
  font-size: var(--portal-font-size-gen);
  cursor: pointer;
}

.btn_base:hover{
  background-color: var(--portal-colour-first);
}

.btn_2{
  background-color: var(--portal-colour-first);
}

.btn_2:hover{
  background-color: var(--portal-colour-second);
}


.btn_1{
  background-color: var(--portal-colour-first);
}

.btn_1:hover{
  background-color: var(--portal-colour-second);
}


.login_con .btn_base{
  margin-top: 20px ;
  box-shadow:var(--portal-shadow-gen);
}

.login_con .forgot_text{
  font-size: var(--portal-font-size-gen);
  color: var(--portal-colour-second);
  margin-top: 10px;

}

.login_con .forgot_text a{
  font-size: var(--portal-font-size-gen);
  color: var(--portal-colour-second);
  margin-top: 10px;
  text-decoration: underline;
}

.login_con .forgot_text a:hover{
  text-decoration: none;
}

.checkbox_div {
  margin-top: 10px;
  
}

.checkbox_div label{
  font-weight: normal !important;
}

.checkbox_div label a{
  color: var(--portal-colour-second);
  text-decoration: none;
  font-weight: bold !important;
}

.checkbox_div input{
  width: auto !important;
  float: left;
  margin-right: 10px;
  /* vertical-align: top;
  display: table-cell; */
  height: auto !important;
}


.portal_con{
  width: 100vw;
  height: 100vh;
}

.portal_sidenav{
  display: flex;
  flex-direction: column;
}

.portal_sidenav .navlink{
  margin-bottom: 15px;
  width: 100%;
  align-items:center;
  height: 30px;
  display: flex;
  font-size: var(--portal-font-size-med);

}

.portal_sidenav .navlink:hover{
  padding-right: 10px;
  opacity: .4;
  cursor: pointer;
}

.portal_sidenav .navlink img{
  padding-right:10px;
}

.portal_con .portal_header{
  background-color: #FFF;
  position: relative;
  height: 50px;
}

.portal_con .portal_header .portal_name{
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-left: 20px;
  font-size: var(--portal-font-size-large);
  font-weight: bold;
}

.portal_username{
  position: absolute;
  right: 0px;
  top: 0px;
  height: 50px;
  display: table-cell;
  vertical-align: middle;
  padding-right: 20px;
}

.portal_username{
  display: flex;
  gap: 5px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.portal_username .username_abv{
  background-color: var(--portal-colour-first);
  color: #FFF;
  font-size: 15px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portal_username .username_fullname{
  color: var(--portal-colour-second);
  font-size: var(--portal-font-size-med);
}

.portal_con .portal_sidenav{
  padding: 40px;
  background-color: var(--portal-colour-first);
  color: #FFF;
  position: absolute;
  width: 300px;
  left: 0px;
  top: 50px;
  height: 100vh;
  box-sizing: border-box;
  display: table;
}


.portal_con .portal_content_con{
  box-sizing: border-box;
  display: table;
  width: 100%;
  height: 100vh;
  padding: 40px;
  padding-left: 340px;
}

.portal_con .portal_content_heading{
  font-size: var(--portal-font-size-xlarge);
  margin-bottom: 30px;

}

.portal_con .portal_content{
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.sum_box{
  box-shadow:var(--portal-shadow-gen);
  border-radius: var(--portal-round-corners);
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  position: relative;
  gap: 5px;
  align-items: center;
  min-height: 90px;
  background-color: #FFF;
  margin-bottom: 30px;
}

.sum_box .left{
  width: 100%;
  text-align: left;
  display: flex;
  gap: 5px;
  justify-content:start;
}

.sum_box .left .sum_box_col{
  width: 100%;
}

.num{
  font-weight: bold;
  color: var(--portal-colour-first);
}

.sum_box .right{
  width: 500px;
  display: flex;
  gap: 5px;
  justify-content: space-evenly;
}

.btn_col{
  /* margin-right: auto; */
  width: 120px;

}

.sum_box .sum_status_con{
  position: absolute;
  top: 10px;
  left: -5px;
}


.sum_box .status{
  background-color: #9A9A9A;
  font-size: 12px;
  color: #FFF;
  padding-left: 10px;
  padding-right: 10px;

}

.sum_status_con{
  display: flex;
  gap: 2px;
}

.portal_status_1{
  background-color: var(--portal-status-color-one) !important;
}

.portal_status_2{
  background-color: var(--portal-status-color-two) !important;
}

.portal_status_3{
  background-color: var(--portal-status-color-three) !important;
}


.info_box{
  box-shadow:var(--portal-shadow-gen);
  border-radius: var(--portal-round-corners);
  background-color: #FFF;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  padding-top: 60px;
  margin-bottom: 40px;
}

.info_box .leftright_con{
  display: flex;
  justify-content:space-evenly;
  margin-bottom: 20px;
}

.info_box .leftright_con div{
  width: 100%;
}

.info_box .supportrequired_row_con{
  display: flex;
  gap: 3px;
  font-size: 14px;;

}


.info_box .supportrequired_row_con div{
  margin-bottom: 5px;
}

.info_box .supportrequired_row_con .right{
  color: var(--portal-colour-first);
  cursor: pointer;
}

.info_box .portal_notes{
  background-color: var(--portal-colour-gen-light);
  padding: 20px;
  border-radius: var(--portal-round-corners);
  box-sizing: border-box;
  color: var(--portal-colour-second);

}

.info_box .upload_con{
  background-color: var(--portal-colour-first);
  padding: 10px;
  box-sizing: border-box;
  
  margin-top: 20px;
  border-radius: 10px;
  
}

.info_box .upload_con .sub_heading{
  font-weight: bold;
  color: #FFF;
  margin-bottom: 20px;
  font-size: 18px;
}



.info_box .upload_con .uploadcon{
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
  box-sizing: border-box;
}

.info_box .upload_con input{
  background: #FFF;
  padding: 3px;
}

.info_box .upload_con .btn_base{
  width: 100px;
}


.info_box .supporting_box{
  border-top: 1px var(--portal-colour-second) dotted;
  display: table;
  margin-top: 20px;
  padding-top: 20px;
}

.info_box .supporting_box .sub_heading{
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;

}

.info_box .det_heading_large{
  font-size: var(--portal-font-size-xlarge);
  color: var(--portal-colour-second);
  margin-bottom: 5px;
}

.info_box .det_heading_sub{
  font-size: var(--portal-font-size-med);
  color: var(--portal-colour-gen-light);
  margin-bottom: 10px;
}

.info_box .det_heading_price{
  font-size: var(--portal-font-size-large);
  color: var(--portal-colour-first);
  margin-bottom: 10px;
  font-weight: bold;
}

.info_box .portal_det_row_con{
  display: flex;
  justify-content: space-evenly;
  width: 300px;
  margin-bottom: 5px;

}

.info_box .portal_det_row_con div{
  text-align: left;
  width: 100%;
}

.info_box .portal_det_row_con div:first-of-type{
  font-weight: bold;
}

.info_box .status{
  background-color: #9A9A9A;
  font-size: 16px;
  color: #FFF;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;

}



.sum_box .yesno_col{
  display: flex;
  gap: 5px;
}

.info_box .yesno_col{
  display: flex;
  gap: 5px;
}

.info_box .yesno_col div{
  width: 100% !important;
  font-size: 13px;
}

.info_box .support_row_con{
  display: flex;
  justify-content: space-evenly;
  
  width: 100%;
  text-align: left;
  border-top: 1px solid var(--portal-colour-gen-light);
  padding: 10px;
  /* cursor: pointer; */
  box-sizing: border-box;

}

.info_box .support_row_con:hover{
  background-color: var(--portal-colour-gen-light);
}

.info_box .support_row_con .download_link:hover{
  color: var(--portal-colour-first);
  cursor: pointer;

}

.info_box .support_row_con .left{
  display: flex;
  justify-content:start;
  gap: 10px;
  width: 100%;
}

.info_box .support_row_con .left div{
  width: 100%;
  float: right;
}

.info_box .support_row_con .left .btn_base{
  width: 100px;

}

.info_box .support_row_con .left .btn_3{
  background-color: var(--portal-status-color-three);
}

.info_box .support_row_con .right{
  display: flex;
  justify-content:end;
  gap: 10px;
  width: 100%;
}

.info_box .support_row_con .review_status{
  background-color: var(--portal-colour-gen-light);
  padding: 5px;
  font-size: 12px;
  width: 200px !important;
  text-align: center;
  color: #FFF;
}

.info_box .support_row_con .review_status_1{
  background-color: var(--portal-status-color-two);
}

.info_box .support_row_con .review_status_2{
  background-color: var(--portal-status-color-one);
}

.info_box .support_row_con .review_status_3{
  background-color: var(--portal-status-color-three);
}




.info_status_con{
  display: flex;
  gap: 2px;
  position: absolute;
  top: 15px;
  right: -10px;
}
.wide_con{
  margin-top: 30px;
}

.wide_con .sub_heading{
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;

}


.profile_box .form_con form{
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: var(--portal-colour-gen-light);
  border-radius: 5px;
  margin-bottom: 20px;
}

.profile_box .form_con form div{
  flex: 1 0 33%; 
  display: flex;
  gap: 5px;
  flex-flow: column wrap;
  position: relative;
}

.profile_box .form_con form button{
  width: 100%;
  display: table;
  background-color: var(--portal-colour-second);
  border-radius: var(--portal-round-corners);
  font-size: var(--portal-shadow-gen);
  color: #FFF;
  text-align: center;
  border: none;
  line-height: 35px;
  cursor: pointer;
  box-shadow: var(--portal-shadow-gen);
}

.profile_box .form_con form button:hover{
  background-color: var(--portal-colour-first);
}

.profile_box .form_con form div label{
  display: block;
  font-size: var(--portal-font-size-gen);
  font-weight: bold;
}

.profile_box .form_con form div input{
  width: 100%;
  background-color: #FFF;
  font-size: var(--portal-font-size-gen);
  line-height: 35px;
  padding-left: 15px;
  color: var(--portal-colour-second);
  box-sizing: border-box;
  border: 0px;
  border-radius: var(--portal-round-corners);
}

.profile_box .btn_base{
  width: 200px;
  float: right;
  display: table;
}

.profile_box{
  display: table;
}

@media only screen and (max-width:1400px) {

}

@media only screen and (max-width:1200px) {

  .sum_box{
    display: block;
    padding-top: 40px;
  }

  .sum_box .left{
    margin-bottom: 30px;
  }

  .sum_box .right{
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content:right;
  }
  

}

@media only screen and (max-width:1024px) {

}

@media only screen and (max-width:768px) {

  .portal_con .portal_sidenav{
    display: none;
  }

  .portal_con .portal_content_con{
    padding-left: 40px;
  }

  .sum_box .left{
   display: flex;
   flex-direction: column;
   gap: 5px;
  }

  .sum_box .right{
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content:space-evenly
  }

  .sum_box .right div{
    width: 100%;
  }

}

@media only screen and (max-width:480px) {
 
}

@media only screen and (max-width:320px) {

}