:root{
  --font-fam:'Lato', sans-serif;
  --sidebar-bg:rgba(255,255,255,.8);
  --single-prop-bg:rgba(255,255,255,.9);
  --colour-first:#2BC687 !important;
  --colour-second:#333336;
  --font-size-xxxlarge:45px;
  --font-size-xxlarge:35px;
  --font-size-xlarge:25px;
  --font-size-large:16px;
  --font-size-med:19px;
  --font-size-gen:13px;
  --font-size-small:10px;
  --font-size-xsmall:8px;
  --round-corners:5px;
  --shadow-gen:0px 0px 15px -4px rgba(0,0,0,0.5);
  --status-color-one:#2BC687;
  --status-color-two:#FBC130;
  --status-color-three:#EC0000;
  --colour-gen-light:#9A9A9A;
}


.powered_by{
  width: 100%;
  background-color: #FFF;
  border-top: 1px solid rgba(0, 0, 0, .1);
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 9999 !important;

  width: 350px;
  text-align: center;
  font-size: 13px;
  padding: 5px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: var(--colour-second);
  box-sizing: border-box;
  font-weight: bold;

}

.powered_by a{
  text-decoration: none;
  color:#2BC687;
}

.reload_link{
  font-size: var(--font-size-gen);
  cursor: pointer;
  margin-top: 10px;
  color: var(--colour-second) !important;
}

.reload_link:hover{
  opacity: .5;
}

@keyframes blink {
  0% {
    opacity: 1;
    /* border: 3px solid #EC0000; */
    /* background-color: #FF2CEB; */
  }
  50% {
    opacity: .2;
    /* border: 3px solid #EC0000; */
    /* background-color: #8345FF; */
  }
  100% {
    opacity: 1;
    /* border: 3px solid #EC0000; */
    /* background-color: #FF2CEB; */
  }
}

.plot_selected_marker_little{
  display: flex;
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
  /* background-color: #8345FF; */
  border: 5px solid #EC0000;
  color: #FFF;
  /* border: 0px; */
  padding: 0px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  animation: blink 1s ease-in-out infinite; 
}

.plot_selected_marker_little img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}


.plot_selected_marker_little:before{
  display: none;
}

.image_con_main{
  width: calc(100vw - 40px);
  max-width: 1100px;
  margin: 0 auto;
  display: table;
  position: relative;
  height: calc(100vh - 120px); 
  height: auto;
  /* max-height: 100vh; */
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-size: contain;
  /* background-size: auto 100%; */
  background-position: center;
  z-index: 999999;
  top: 60px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 5px solid #FFF;
  background-color: #FFF;
  box-shadow: var(--shadow-gen);
  box-shadow: 1px 0px 33px 9px rgba(0,0,0,0.92);
-webkit-box-shadow: 1px 0px 33px 9px rgba(0,0,0,0.92);
-moz-box-shadow: 1px 0px 33px 9px rgba(0,0,0,0.92);

  aspect-ratio: 16 / 9;
  
}

.image_pop_title{
  position: absolute;
  top: -45px;
  left: 0px;
  font-size: 20px;
  color: #FFF;
}

.image_pop_copy{
  position: absolute;
  bottom: -40px;
  left: 0px;
  font-size: 15px;
  color: #FFF;
}

/* .image_con_main img{
  width: 100%;
  height: auto;
} */

.leaflet-popup-tip {
  width: 0px;
  height: 0px;
}


.sidebar_enquiry.active{
  left: 0px !important;
}


.sidebar_enquiry{
  background-color: rgba(216, 216, 216, 1) !important;
  left: -450px !important;
  transition: all  0.3s ease;
}
.single_property_no_hover:hover{
  cursor: default !important;
  background-color: #FFF !important;
  box-shadow: none !important;
  box-shadow: var(--shadow-gen) !important;
}

.sidebar_topper_header_con{
  width: 100%;
  color: var(--colour-second);
  font-size: var(--font-size-xlarge);
  font-weight: bold;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #c0c0c0;
}

.sidebar_topper_back:hover{
  opacity: .5;
  cursor: pointer;

}

.cool_sub_heading{
  color: var(--colour-second);
  font-size: var(--font-size-med);
  font-weight: bold;
  margin-bottom: 5px;
}

.get_intouch{
  margin-top: 30px;
  border-top: 1px solid var(--colour-gen-light);
  padding-top: 20px;
}

.cool_smallsub_heading{
  color: var(--colour-second);
  font-size: var(--font-size-gen);
  margin-bottom: 10px;
}

.full_details_con{

  display: block;
  width: calc(100% + 0px);
  height: calc(100vh - 100px);
  overflow-y:  scroll !important;
  overflow-x: hidden !important;
  /* padding: 15px; */
  padding-bottom: 50px;
  box-sizing: border-box;
  /* margin-left: -15px; */
}

.sidebar_enquiry form{
  display: table;
  width: 100%;
  box-sizing: border-box;
}

.sidebar_enquiry form input{
  box-sizing: border-box;
  display: table;
  width: 100%;
  border: 0px;
  border-radius: 5px;
  font-size: var(--font-size-gen);
  height: 30px;
  margin-bottom: 5px;
  padding-left: 5px;

}

.sidebar_enquiry form > div{
  position: relative;
}

.sidebar_enquiry form label{
  font-size: var(--font-size-gen);
  color: var(--colour-second);
  margin-bottom: 5px;
  display: table;
}

.sidebar_enquiry .btn_base{
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  height: 35px;
  margin-top: 10px;
  box-shadow: var(--shadow-gen);
}

.sidebar_enquiry .btn_base > div{
  width: 100%;
}

.sidebar_enquiry .btn_base > div:last-of-type{
  width: 30px;
}

.floor_plan_div{
  width: 100%;
  height: 200px;
  background-color: #FFF;
  background-color: rgba(255,255,255,.9);
  background-color: var(--single-prop-bg);
  box-shadow: 0px 0px 15px -4px rgb(0 0 0 / 50%);
  box-shadow: var(--shadow-gen);
  border-radius: 5px;
  border-radius: var(--round-corners);
  margin-bottom: 10px;
  opacity: 1;
  cursor: pointer;
  transition: all  0.3s ease;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow:0px 0px 0px 10px #FFF inset;
  box-sizing: border-box;
}

.floor_plan_div:hover{
  opacity: .7;
}

.enlarge_icon{
  background-color: #3B3B3D;
  display: table;
  margin-left: 10px;
  width: 30px;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 0 0 5px 5px;

}


.enlarge_icon img{
  width: 100%;
  height: auto;
}

.logo{
  text-align: center;
  font-size: var(--font-size-med);
  font-weight: bold;
  color: var(--colour-second);
  margin-bottom: 20px;

}

.logo img{
  width: auto;
  height: 100%;
  max-height: 100px;
  
}


.error_large{
  background-color: var(  --status-color-three);
  color: #FFF;
  font-size: var(--font-size-gen);
  margin-bottom: 10px;
  padding:10px;

  
}

.error_label{
  color: var(--status-color-three);
  font-size: 10px;
  position: absolute;
  right: 5px;
  top: 25px;
  font-weight: normal;
}

.into_text{
  margin-bottom: 10px;
  font-size: var(--font-size-gen);
  line-height: 22px;;
}
.formerror_con{
  background-color: var(  --status-color-three);
  color: #FFF;
  padding: 15px;
  font-size: var(--font-size-gen);
  margin-bottom: 10px;
}

.messagealert{
  background-color: var(--colour-second);
  padding: 20px;
  color: #FFF;
  margin: 20px;
  position: absolute;
  z-index: 9999;
  position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 350px; /* Need a specific value to work */
  box-shadow: var(--shadow-gen);
  display: flex;
  gap: 5px;
  border-left: 5px solid var(--colour-first);
  top: -500px;
  /* top:50px; */
  transition: all  .25s ease;
}

.messagealert.active{
  top: 50px;  
}

.messagealert2{
  border-left: 5px solid var(--status-color-two);
}

.messagealert3{
  border-left: 5px solid var(--status-color-three);
}

.messagealert.alert_1{
  border-left: 5px solid var(--status-color-one);
}

.messagealert.alert_2{
  border-left: 5px solid var(--status-color-two);
}

.messagealert.alert_3{
  border-left: 5px solid var(--status-color-two);
}

.messagealert  .message_icon{
  width: 60px;
}
.messagealert .message_con{
  width: 100%;
  color: #FFF;
  font-size: 16px;
}

.messagealert .close_message{
  width: 30px;

}

.messagealert .close_message:hover{
  opacity: .5;
  cursor: pointer;
}


.close_btn{
  position: absolute;
  z-index: 9999;
  color: #FFF;
  right: 30px;
  top: 20px;
  color: #FFF;
  font-size: var(--font-size-gen);
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  
}

.close_btn img{
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}

.close_btn.close_btn_smaller{
  font-size: var(--font-size-small);
}

.close_btn:hover{
  color: var(--status-color-three);
  /* background-color: var(--status-color-three); */
}


.close_btn2{
  position: absolute;
  z-index: 9999;
  color: #FFF;
  right: 0px;
  top: -40px;
  color: #FFF;
  font-size: var(--font-size-gen);
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  gap: 3px;
  
}

.close_btn2 img{
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}

.close_btn2.close_btn_smaller{
  font-size: var(--font-size-small);
}

.close_btn2:hover{

  opacity: .4;

}

.btn_loading{
  /* display: flex !important;
  width: auto; */
  gap: 10px;
  background-color: var(--colour-second) !important;
  cursor: not-allowed !important;
}


.btn_loading > div{
  
}


.inner_root_container{
  width: 100%;
  height: 100vh;
}



.legend_container{
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #FFF;
  border-radius: var(--round-corners);
  box-shadow: var(--shadow-gen);
  z-index: 999;
  padding: 6px;
  box-sizing: border-box;
  display: table;
  width: 100px;
  
}

.legend_floors{
  top: 110px;
 
}

.legend_floors > div{
  background-color: var(--colour-second) !important;
  cursor: pointer;
  display: table;
  border-radius: 25px;
  text-align: center;
  font-size: 10px;
  color: #FFF !important;
  font-weight: normal !important;
}

.legend_floors > div:hover{
  background-color: var(--status-color-one) !important;
}

.legend_floors > div.active{
  background-color: var(--status-color-one) !important;
}

.legend_container > div{
  /* background-color: var(--status-color-one); */
  color: var(--colour-second);
  font-weight: bold;
  font-size: 11px;
  /* text-align: center; */
  box-sizing: border-box;
  /* display: table; */
  /* border-radius: 25px; */
  padding: 1px;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
}

.legend_container span{
  width: 10px;
  height: 10px;
  border-radius: 25px;
  background-color: var(--colour-gen-light);
  display: inline-block;
  margin-right: 8px;
}

.legend_container .legend_1 span{
  background-color: var(--status-color-one);
}

.legend_container .legend_2 span{
  background-color: var(--status-color-two);
}

.legend_container .legend_3 span{
  background-color: var(--status-color-three);
}

.leaflet-container, #map{
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 0;
}


body {
  margin: 0;
  padding: 0;
  font-family: var(--font-fam);
  background-color: silver;
}

.btn_base{
  background-color: var(--colour-second);
  color: #FFF;
  border-radius: var(--round-corners);
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  box-sizing: border-box;
  font-size: var(--font-size-gen);
  cursor: pointer;
  transition: all  0.3s ease;
}

.btn_base:hover{
  background-color: var(--colour-first);
}

.btn_2{
  background-color: var(--colour-first);
}

.btn_2:hover{
  background-color: var(--colour-second);
}


.sidebar{
  background-color: var(--sidebar-bg);
  color: #FFF;
  position: fixed;
  left: 0px;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px;
  width: 350px;
  overflow: hidden;
  z-index: 999;
  display: table;
}

.floor_select{
  display: table;
  width: 100%;
  font-size: var(--font-size-gen);
  position: relative;
  margin-bottom: 10px;
}

.floor_select .floor_select_btn{
  width: 100%;
  text-align: left;
  position: relative;
}

.floor_select .floor_select_btn img{
  right: 10px;
  position: absolute;
}

.floor_select ul{
  position: absolute;
  background-color: var(--colour-second);
  display: table;
  width: 100%;
  box-sizing: border-box;
  margin-left: 0px;
  padding-left: 0px;
  top: 15px;
  z-index: 99;;
  display: none;
}

.floor_select ul.active{
  display: block;
}

.floor_select ul li{
  margin-left: 0px;
  padding-left: 0px;
  padding: 10px;
  list-style: none;
  cursor: pointer;
}

.floor_select ul li:hover{
  background-color: var(--colour-first);
}

.properties_con{
  display: block;
  width: calc(100% + 30px);
  height:calc(100vh - 100px);
  overflow: scroll;
  padding: 15px;
  padding-bottom: 130px;
  box-sizing: border-box;
  margin-left: -15px;
}

.single_property{
  background-color: var(--single-prop-bg);
  -webkit-box-shadow: var(--shadow-gen);
  -moz-box-shadow: var(--shadow-gen);
  box-shadow: var(--shadow-gen);
  border-radius: var(--round-corners);
  padding: 15px;
  margin-bottom: 10px;
  transition: all  0.3s ease;
  cursor: pointer;
}

.single_property.highlight{
  /* background-color: #e9e9e9;
  box-shadow: inset 0px 0px 0px 1px var(--colour-first); */
  background-color: #666666;
}

.single_property.highlight div{
  color: #FFF !important;
}

.single_property.highlight:hover{
  background-color: #666666;
}


.single_property:hover{
  background-color: #e9e9e9;
  box-shadow: inset 0px 0px 0px 1px var(--colour-first);
}


.single_property .header_con{
  display: flex;
  flex-direction: row; 
  position: relative; 
  margin-bottom: 10px;
}
.single_property .header_con > div{
  width: 100%;
}


.single_property .header_con .status{
  width: 70px;

}

.single_property .header_con .status > div{
  background-color: var(--colour-first);
  font-size: var(--font-size-gen);
  color: #FFF;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: -20px;
}

.single_property .header_con .status_1 > div{
  background-color: var(--status-color-one);
}

.single_property .header_con .status_2 > div{
  background-color: var(--status-color-two);
}

.single_property .header_con .status_3 > div{
  background-color: var(--status-color-three);
}

.single_property .header_con .title{
  color: var(--colour-second);
  font-size: var(--font-size-large);
  font-weight: bold;
}


.single_property .details_rows_con{
margin-top: 20px;
}

.single_property .details_rows_con{
  width: 100%;
  display: table;
  color: var(--colour-gen-light);
  font-size: var(--font-size-gen);
}

.single_property .details_rows_con .detail_row{
  display: flex;
  width: 100%;
}

.single_property .details_rows_con .detail_row > div{
  width: 100%;
  font-weight: bold;
  padding-bottom: 3px;
  padding-top: 3px;
}

.single_property .details_rows_con .detail_row > div.number{
 text-align: right;
 font-weight: normal;
}

.single_property .price{
  display: table;
  width: 100%;
  text-align: right;
  font-weight: bold;
  color: var(--colour-second);
  line-height: 35px;
  margin-bottom: 5px;
  font-size: var(--font-size-med);
}

.single_property .btn_con{
  display: flex;
  width: 100%;
  gap: 5px;

}

.single_property .btn_con > div{
  width: 100%;
}



.single_property .btn_con > div.btn_2{
  box-shadow: var(--shadow-gen);
}

#map { 
  height: 100vh;
  width: 100vw;
}

#map select{
  position: absolute;
  z-index: 9999999;
}

#map .leaflet-bar a{
  background-color: var(--colour-second);
  color: #FFF;
}

#map .leaflet-bar a.leaflet-disabled{
  color: var(--colour-gen-light);
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#map .leaflet-touch .leaflet-control-layers, #map .leaflet-touch .leaflet-bar{
  box-shadow: none;
  border: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar{
  border:none !important;
}

.plot_tooltip{
  background-color: #000;
  border: 2px solid #FFF;
  border-radius: 25px;
  color: #FFF;
  font-size: 13px;
  font-weight: bold;
  min-width: 20px;
  height: 15px;
  vertical-align: middle;
  display: table;
  text-align: center;
}

.recatchcon{
  display: table;
  width: 100%;
  height: 85px;
  margin-top: 10px;
  /* text-align: right; */
}

.recatchcon > div > div > div{
  width: auto !important;
}

.recatchcon > div > div > div{
  /* transform:scale(1.05);
  -webkit-transform:scale(1.05);
  transform-origin:0 0;
  -webkit-transform-origin:0 0; */
  }


#map .plot_class{
  transition: opacity 0.3s ease;
}

#map .plot_class .btn_2{
  box-shadow: var(--shadow-gen);

}

#map .plot_class .pop_header{
  font-weight: bold;
  margin-bottom: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #c0c0c0;
}

#map .plot_class .pop_price{
  font-weight: bold;
  font-size: 22px;
  text-align: right;
  margin-bottom: 10px;

}

/* #map .plot_class:hover{
  opacity: .5;
} */


#map .plot_class_1{
  fill: var( --status-color-one) !important;
}
#map .plot_class_2{
  fill: var( --status-color-two) !important;
}
#map .plot_class_3{
  fill: var( --status-color-three) !important;
}

.leaflet-popup .leaflet-popup-content-wrapper{
  background-color:#262626 !important;
  border-radius: 0px !important;
  color: #FFF !important;
}




.leaflet-popup .leaflet-popup-tip-container .leaflet-popup-tip{
  background-color: #262626 !important;
}



.marker_photo{
  border-radius: 100%;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px 2px rgba(0,0,0,0.4);
  -webkit-box-shadow: 0px 10px 10px 2px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 20px 10px 2px rgba(0,0,0,0.4);
}

.marker_photo:hover{
  opacity: .7;
}


.marker_location{
  border-radius: 100%;
  width: 40px !important;
  height: 40px;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px 2px rgba(0,0,0,0.4);
  -webkit-box-shadow: 0px 10px 10px 2px rgba(0,0,0,0.4);
  -moz-box-shadow: 0px 20px 10px 2px rgba(0,0,0,0.4);
}


.marker_photo div{
  width: 100%;
  height: auto;
}

.marker_photo img{
  width: 100%;
  height: auto;
}

.backdrop{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  z-index: 99999;
  top: 0px;

}


.pop_next{
  position: absolute;
  right: 20px;
  top: calc(50%);
}

.pop_next:hover, .pop_prev:hover{
  opacity: .6;
  cursor: pointer;
}

.pop_prev{
  position: absolute;
  left: 20px;
  top: calc(50%);
}

.rl_con{
  display: flex;
}

.left_con{
  padding: 20px;
  width: calc(100% - 450px);
  display: table;
}

.right_con{
  padding: 20px;
  width: 450px;
  display: table;
}

.help_btn{
  position: absolute;
  right: 0px;
  top: 35px;
  font-size: 13px;
  background: var(--status-color-two);
  color: #FFF;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 5px 0px 0px 5px ;
  cursor: pointer;
  vertical-align: top;
  display: none;
}

.help_btn img{
  vertical-align: top;
  padding-top: 1px;
  padding-right: 5px;
}

.help_btn:hover{
  background: var(--colour-second);
}


.bookingmodal{
  z-index: 99999;
  width: calc(100vw - 60px);
  background-color: #FFF;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 50px;
  border-radius: var(--round-corners);
  overflow: hidden;
}

.bookingmodal .counter{
  background-color: var(--status-color-one);
  color: #FFF;
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  margin: 0 auto;
  padding: 5px;
  border-radius: 5px 5px 0px 0px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 10px;
  
}

.bookingmodal .mainheading{
  margin: 0 auto;
  text-align: left;
  font-size: var(--font-size-xlarge);
  font-weight: bold;
  line-height: 25px;
  display: inline-block;
  margin-bottom: 20px;

}

.bookingmodal .mainheading img{
  width: 25px;
  height: auto;
  margin-right: 8px;
  vertical-align: top;
}

.bookingmodal .helper{
  text-align: center;
  font-size: var(--font-size-gen);
  font-weight: normal;
  margin-top: 10px;
}

.bookingmodal .helper span{
  background-color: #c0c0c0;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: bold;
  border-radius: var(--round-corners);
}

.bookingmodal .steps_con_line {
  border-bottom: 2px dashed var(--colour-second);
  position: absolute;
  display: table;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  top: 10px;
  width: 65% !important;
}

.bookingmodal .single_property{
  margin-bottom: 20px;
}

.bookingmodal .steps_con{
  /* margin: 0 auto;
  width: 100%;
  display: table;*/
  padding-right:20px;
  box-sizing: border-box; 

}

.right_con .close{
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  display: none;
}

.right_con .close:hover{
  opacity: .5;
}

.help_con {
  box-sizing: border-box;
  display: table;
}

.help_con .heading{
  font-weight: bold;
  font-size: var(--font-size-med);
  margin-bottom: 20px;
}

.bookingmodal .steps_con div{
  font-size: var(--font-size-gen);
  width: 100%;
  padding-left: 25px;
  margin-left: 15px;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 30px;
  padding-top: 5px;
  border-left: 1px dotted var(--colour-gen-light);
}

.bookingmodal .steps_con div:last-of-type{
  padding-bottom: 0px;
  border-left: none;
}

.bookingmodal .steps_con div.active span{
  background-color: var(--status-color-one);
}

.bookingmodal .steps_con div span{
  width: 25px;
  height: 25px;
  background-color: var(--colour-gen-light);
  display: table-cell;
  line-height: 25px;
  border-radius: 100%;
  left: -12px;
  z-index: 999;
  position: absolute;
  text-align: center;
  color: #FFF;
  top: 0px;
}

.bookingmodal .steps_con div.round.active{
  background-color: var(--colour-first) !important;
}

.bookingmodal .steps_con div b{
  display: table;
  text-align: center;
  width: 100%;
}

.bookingmodal .form_con{
  width: 100%;
  margin-bottom: 20px;
  display: table;
}

.bookingmodal .form_con .btn_2{
  box-shadow: var(--shadow-gen);
  width: auto;
  display: table;
  font-size: var(--font-size-large) !important;
  float: right;
}

.bookingmodal .form_con .btn_2.btn_close{
  float: left;
  /* background-color: var(--colour-second); */
  background-color: #FFF;
  box-shadow: none;
  color: var(--colour-second);
} 

.bookingmodal .form_con .btn_2.btn_close:hover{
  color:var(--status-color-three) !important;
}

.bookingmodal .form_con .btn_2 img{
  vertical-align: top;
  margin-left: 5px;
}

.bookingmodal .form_con form{
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #D8D8D8;
  border-radius: 5px;
  margin-bottom: 20px;
  /* box-shadow: ; */
}

.bookingmodal .form_con form div{
  flex: 1 0 33%; 
  display: flex;
  gap: 5px;
  flex-flow: column wrap;
  position: relative;
}

.bookingmodal .form_con form button{
  width: 100%;
  display: table;
  background-color: var(--colour-second);
  border-radius: var(--round-corners);
  font-size: var(--shadow-gen);
  color: #FFF;
  text-align: center;
  border: none;
  line-height: 35px;
  cursor: pointer;
  box-shadow: var(--shadow-gen);
}

.bookingmodal .form_con form button:hover{
  background-color: var(--colour-first);
}

.bookingmodal .form_con form div label{
  display: block;
  font-size: var(--font-size-gen);
  font-weight: bold;
}

.bookingmodal .form_con form div input{
  width: 100%;
  background-color: #FFF;
  font-size: var(--font-size-gen);
  line-height: 35px;
  padding-left: 15px;
  color: var(--colour-second);
  box-sizing: border-box;
  border: 0px;
  border-radius: var(--round-corners);
}

.modal{
  position: absolute;
  z-index: 999;
  top: 50px;
  background-color: #FFF;
}



@media only screen and (max-width:1400px) {

}

@media only screen and (max-width:1200px) {

}

@media only screen and (max-width:1024px) {

  .left_con{
    width: calc(100% - 300px);
  }
 
  .right_con{
    width: 300px;
  }
  
}

@media only screen and (max-width:768px) {

  .right_con .close{
    display: block;
  }

  .help_btn{
    display: block;
  }

  .left_con{
    width: 100%;
  }
 
  .right_con{
    position: absolute;
    width: 300px;
    background: #FFF;
    right: -500px;
    box-shadow: var(--shadow-gen);
    padding-top: 40px;
  }

  .right_con.show{
    right: 0px;
  }

  .hide_mobile{
    display: none;
  }
	
}

@media only screen and (max-width:480px) {


  .sidebar{
    width: 100%;
  }


  .bookingmodal .form_con form{
    display: block;
  }
  
  .bookingmodal .form_con form div{
    display: block;
    margin-bottom: 5px;
  }

  .bookingmodal .form_con form div label{
    margin-bottom: 3px;
  }

  .bookingmodal .form_con .btn_2.btn_close{
    display: none;
  }

}

@media only screen and (max-width:320px) {

}