.openloading{
  width: 100%;
  height: 100%;
  display: table;
  position: fixed;
  z-index: 999999;
  /* max-width: 300px; */
  /* margin: 0 auto; */
  font-size: 22px;
  text-align: center;
  padding-top: 100px;
  color: #FFF;
  background-color:#1d1d1de1;
}
.openloading.error{
  color: #FFF;
}

.openloading .loadingicon{
  margin: 0 auto;
  margin-bottom: 20px;
}

.loadingicon {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #2BC687;
  width: 30px !important;
  height: 30px !important;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.loadingicon_1{
  width: 12px !important;
  height: 12px !important;
}

.loadingicon_2{
  width: 25px !important;
  height: 25px !important;
}

.loadingicon_3{
  width: 50px !important;
  height: 50px !important;
}

.error_icon{
  border: 3px solid #EC0000;
  border-radius: 50%;
  width: 50px !important;
  height: 50px !important;
  margin: 0 auto;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #EC0000;

}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.refresh_btn{
  font-weight: bold;
  text-transform: uppercase;
  color: #FFF;
  cursor: pointer;
  margin-top: 50px;
  font-size: 12px;
  cursor: pointer;
  display: block
}

.refresh_btn img{
  width: 20px;
  height: 20px;
  clear: both;
  margin-bottom: 5px;
}

.refresh_btn:hover{
  opacity: .5;
}